import React, { useState } from 'react';
import { View, StyleSheet } from "react-native";
import { Edit, Star } from "@carbon/icons-react"

import { ConversationScreen, HorizBox, Pad, PadBox } from "np-platform-client/component/basics"
import { CTAButton, TextButton } from "np-platform-client/component/button";
import { UtilityText, Heading } from "np-platform-client/component/text";
import { gotoInstance, gotoInstanceScreen } from "np-platform-client/util/navigate";
import { useInstanceKey, useStructureKey, useDatastore, useObject, useCollection } from "np-platform-client/util/datastore";
import { useIsAdmin } from "np-platform-client/component/admin";
import { colorPinkBackground, colorTeaserBackground, colorTextGrey } from "np-platform-client/component/color"
import { useConfig } from "np-platform-client/util/features";
import { Toggle } from "np-platform-client/component/form";
import { Catcher } from "np-platform-client/system/catcher";

import { keyToUrl, toBool } from "../../../util/util";
import { TeaserScreen } from "../../../component/teaser";
import { TeaserButton, CommentPreview } from '../../connect/EditorialQuestionFeature';
import { useGlobalTopics } from "../admin/TopicCreationFeature";

export const TopicTeaserFeature = {
    name: 'Topic Teaser',
    key: 'topicteaser',
    config: {
        teaser: EditorialTopicTeaser,
        teaserSingleQuestion: AdaptiveLayoutTopicTeaserWithComment,
        teaserMultiQuestion: DefaultTopicTeaser,
        showZeroStateGraphic: true,
        adminScreen: AdminScreen
    }
}

export function AdminScreen() {
    const topics = useGlobalTopics();
    const [search, setSearch] = useState('');
    const localSelectedTopics = useCollection('topic').filter(q => !q.deleted);

    const filteredTopics = topics?.filter(topic => !search || topic.name?.toLowerCase().includes(search.toLowerCase()));
    const selectedTopics = filteredTopics.filter(topic => localSelectedTopics.find(t => t.key == topic.key));
    const otherTopics = filteredTopics.filter(topic => !selectedTopics.find(t => t.key == topic.key));

    return <ConversationScreen>
        <Pad />
        <PadBox horiz={20}><Heading label='Choose Topics for this Article' /></PadBox>
        <Pad />
        {selectedTopics?.map(topic =>
            <Catcher key={topic.key}><Topic topic={topic} /></Catcher>
        )}
        {otherTopics?.map(topic =>
            <Catcher key={topic.key}><Topic topic={topic} /></Catcher>
        )}
    </ConversationScreen>
}

function Topic({ topic }) {
    const datastore = useDatastore();
    const selectedQuestion = useObject('topic', topic.key);
    const isChosen = selectedQuestion && !selectedQuestion.deleted;

    async function onChange(selected) {
        await datastore.callServerAsync('topics', selected ? 'selectTopic' : 'unselectTopic', { topicKey: topic.key })
    }

    return <PadBox horiz={20}>
        <Toggle spread text={topic.name} value={isChosen} onChange={onChange} />
    </PadBox>

}

export function EditorialTopicTeaser() {
    const isAdmin = useIsAdmin();
    const topics = useCollection('topic').filter(q => !q.deleted);
    const { teaserSingleQuestion } = useConfig();

    if (topics.length === 0) {
        return <TeaserScreen color={colorTeaserBackground}>
            <UtilityText label='No topic for this content' />
            {toBool(isAdmin) && <PadBox top={20}><AdminTeaserAction /></PadBox>}
        </TeaserScreen>;
    }
    const topic = topics[0];

    return <TeaserScreen>
        {React.createElement(teaserSingleQuestion, { topic })}
    </TeaserScreen>;
}

export function AdaptiveLayoutTopicTeaserWithComment({ topic }) {
    const derivedTopics = useCollection('derived_topicPreview');

    const [narrow, setNarrow] = useState(false);
    const isAdmin = useIsAdmin();


    if (derivedTopics.length === 0) {
        return <TeaserScreen color={colorTeaserBackground}>
            <UtilityText label='No topic for this content - No derived topics' />
            {toBool(isAdmin) && <PadBox top={20}><AdminTeaserAction /></PadBox>}
        </TeaserScreen>;
    }

    const { text, key } = topic;
    const topicPreview = useObject('derived_topicPreview', key);

    function onLayout(event) {
        const { width } = event.nativeEvent.layout;
        setNarrow(width < 550);
    }

    return <TeaserScreen>
        <View onLayout={onLayout}>
            {narrow ?
                <NarrowTopicTeaserWithComment title={text} instanceKey={key} commentPreview={topicPreview?.commentPreview} commentCount={topicPreview?.commentCount} />
                :
                <WideTopicTeaserWithComment title={text} instanceKey={key} commentPreview={topicPreview?.commentPreview} commentCount={topicPreview?.commentCount} />
            }
        </View>
        {isAdmin && <PadBox top={20}><AdminTeaserAction /></PadBox>}
    </TeaserScreen>
}

export function NarrowTopicTeaserWithComment({ title, instanceKey, commentPreview, commentCount }) {
    const s = NarrowTopicTeaserStyle;
    const { showZeroStateGraphic } = useConfig();
    return <View style={s.outer}>
        <PadBox horiz={20} vert={20}>
            <TopicInfo title={title} structureKey={"topic"} instanceKey={instanceKey} commentCount={commentCount} />
        </PadBox>
        <View style={s.comment}>
            {(commentPreview || showZeroStateGraphic) &&
                <PadBox bottom={20}><CommentPreview commentPreview={commentPreview} /></PadBox>
            }
            <TeaserButton label='Share your thoughts' icon={<Edit color='white' />} wide={true}
                onPress={() => gotoInstance({ structureKey: 'topic', instanceKey })}
            />
        </View>
    </View>
}

const NarrowTopicTeaserStyle = StyleSheet.create({
    outer: {
        backgroundColor: colorTeaserBackground,
        borderRadius: 10,
    },
    comment: {
        backgroundColor: '#E8EFF9',
        padding: 20,
        flex: 1,
        justifyContent: 'center',
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
    }
})


export function WideTopicTeaserWithComment({ title, instanceKey, commentPreview, commentCount }) {
    const s = WideTopicTeaserStyle;
    const { showZeroStateGraphic } = useConfig()
    return <View style={s.outer}>
        <View style={s.left}>
            <TopicInfo title={title} commentCount={commentCount} structureKey={'topic'} instanceKey={instanceKey} />
            <Pad size={20} />
            <TeaserButton label='Share your thoughts' icon={<Edit color='white' />}
                onPress={() => gotoInstance({ structureKey: 'topic', instanceKey })}
            />
        </View>
        <View style={s.right}>
            {(commentPreview || showZeroStateGraphic) &&
                <CommentPreview commentPreview={commentPreview} />
            }
        </View>
    </View>
}

const WideTopicTeaserStyle = StyleSheet.create({
    outer: {
        borderRadius: 10,
        flexDirection: 'row',
    },
    left: {
        backgroundColor: colorTeaserBackground,
        padding: 24,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        flex: 1,
    },
    right: {
        backgroundColor: '#E8EFF9',
        padding: 24,
        flex: 1,
        justifyContent: 'center',
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
    }
})

export function TopicInfo({ title, commentCount, structureKey, instanceKey }) {
    return <View>
        <HorizBox center>
            < Star />
            <Pad size={8} />
            <UtilityText type='tiny' label='Topic of the moment' caps />
        </HorizBox>
        <Pad size={28} />
        <TextButton heading type='large' text={title}
            onPress={() => gotoInstance({ structureKey, instanceKey })}
        />
        <Pad size={8} />
        {commentCount ?
            <UtilityText type='label' label='{count} {noun}' color={colorTextGrey}
                formatParams={{ count: commentCount, singular: 'participant', plural: 'participants' }}
            />
            :
            <UtilityText type='label' label='Be the first to share your thoughts' color={colorTextGrey} />
        }
    </View>
}

export function DefaultTopicTeaser() {
    const s = MiniTopicPromoStyle;
    const isAdmin = useIsAdmin();
    const topics = useGlobalTopics();
    if (topics.length === 0) {
        return <TeaserScreen color={colorPinkBackground}>
            <UtilityText label='No topic for this content' />
            {toBool(isAdmin) && <PadBox top={20}><AdminTeaserAction /></PadBox>}
        </TeaserScreen>;
    } else {
        const { topic, key } = topics[0];
        return (<TeaserScreen color={colorPinkBackground}>
            <PadBox horiz={20} vert={20}>
                <HorizBox center>
                    <Pad size={8} />
                    <UtilityText type='tiny' label='Topic of the moment' caps strong />
                </HorizBox>
                <Pad size={16} />
                <View>
                    <HorizBox center>
                        <View style={{ flex: 1 }}>
                            <TextButton level={5} editorial style={s.topic} text={topic.name}
                                onPress={() => gotoInstance({ structureKey: 'topic', instanceKey: key })}
                            />
                        </View>
                        <CTAButton label='Join' onPress={() => gotoInstance({ structureKey: 'topic', instanceKey: key })} />
                    </HorizBox>
                </View>
                {toBool(isAdmin) && <PadBox top={20}><AdminTeaserAction /></PadBox>}
            </PadBox>
        </TeaserScreen>);
    }
}

function AdminTeaserAction() {
    const instanceKey = useInstanceKey();
    const structureKey = useStructureKey();
    return (
        <View>
            <CTAButton type='secondary' label='Create a topic'
                onPress={() => gotoInstanceScreen({ structureKey: 'topic', instanceKey: 'admin', screenKey: 'admin', params: {} })} />
            <CTAButton type='secondary' label='Edit topics (admin)'
                onPress={() => gotoInstanceScreen({ structureKey, instanceKey, screenKey: 'admin', params: {} })} />
        </View>)
}

const MiniTopicPromoStyle = StyleSheet.create({
    container: {
        backgroundColor: colorPinkBackground
    },
    topic: {
        fontFamily: 'PlayfairDisplay_400Regular',
        fontSize: 18,
    },
})
