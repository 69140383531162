import React from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import { Heading, UtilityText } from "np-platform-client/component/text";
import { colorBlack, colorGreyBorder, colorLightBlueBackground, colorTextBlue, colorTextGrey } from "np-platform-client/component/color";

const BlacklistList = React.memo(function renderList({ data, selectedTerms, onSelectTerm }) {
    const handleSelect = (term, index, event) => {
        const shiftPressed = event.shiftKey;
        onSelectTerm(term, index, shiftPressed);
    };

    return (
        <>
            {data.length === 0 ? (
                <View>
                    <Heading label="Blacklist is empty." level={4} /> 
                </View>
            ) : (
                data.map((term, index) => {
                    const isSelected = selectedTerms.includes(term);
                    return (
                        <TouchableOpacity
                            key={index}
                            style={[styles.tableRow, isSelected && styles.selectedTerm]}
                            onPress={(event) => handleSelect(term, index, event)}
                        >
                            <UtilityText text={term} color={isSelected ? colorTextBlue: colorBlack}></UtilityText>
                            <UtilityText
                                label={isSelected ? 'Deselect' : 'Select'}
                                level={5}
                                color={isSelected ? colorTextBlue : colorTextGrey }
                            />
                        </TouchableOpacity>
                    );
                })
            )}
        </>
    );
});

const styles = StyleSheet.create({
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: colorGreyBorder,
    },
    selectedTerm: {
        backgroundColor: colorLightBlueBackground,
    }
});

export default BlacklistList;


