import { StructureDemo } from "np-platform-client/util/instance"

const serverCall = {
    eventlog: {
        logEvent: () => { },
    },
    moderation: {
        // make the regular moderation simply pass through
        moderateComment: () => {
            return { violates: false }
        }
    },
    moderationZdf: {
        checkBlacklist: ({ text }) => {
            if (text.includes("cat")) {
                return {
                    violates: true
                }
            } else {
                return {
                    violates: false
                }
            }
        }
    }
}


const globals = {
    name: 'Blacklist demo: The word "cat" is not allowed here.',
    questionAuthor: 'a',
    questionTime: Date.now(),
}

// TODO make datastore.getConfig() work for demos
export function BlacklistFeatureDemo() {
    return <StructureDemo
        structureKey={"question"}
        serverCall={serverCall}
        collections={{

        }}
        globals={globals}
        //features={{ "premoderation": true, "blacklist": true }}
        >
    </StructureDemo>
}