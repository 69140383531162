import React from 'react';
import { newStructures } from './structure';
import { useLiveUrl } from 'np-platform-client/util/url';
import { getScreenStackForUrl } from 'np-platform-client/util/navigate';
import { addStructures } from 'np-platform-client/structure';
import { addTranslations } from 'np-platform-client/translations';
import { newTranslations } from './translations';
import { ScreenStack, getStructureForKey, useStandardFonts } from 'np-platform-client/util/instance';
import { addDefaultFeatures, addFeatures } from 'np-platform-client/feature';
import { newDefaultFeatures, newFeatures, newRoles } from './feature';
import { addEventTypes } from 'np-platform-client/util/eventlog';
import { newEventTypes } from './util/eventtypes';
import { setFirebaseConfig } from 'np-platform-client/util/firebase';
import { UtilityText } from 'np-platform-client/component/text';
import { extendRoles } from 'np-platform-client/component/admin';

const defaultFirebaseConfig = {
    apiKey: "AIzaSyDIg3OR3i51VYrUyUd_L5iIownjdSnExlc",
    authDomain: "np-psi-dev.firebaseapp.com",
    databaseURL: "https://np-psi-dev-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "np-psi-dev",
    storageBucket: "np-psi-dev.appspot.com",
    messagingSenderId: "768032889623",
    appId: "1:768032889623:web:634a1604eda03820ab7552"
};

try {
    const config = require('./production-config');
    setFirebaseConfig(config.firebaseConfig);
    console.log('Using production config');
} catch (e) {
    console.warn('No production config. Using default.');
    console.warn('See https://github.com/wearenewpublic/psi-product/blob/main/docs/deploy.md for more information.')
    setFirebaseConfig(defaultFirebaseConfig);
}

addStructures(newStructures);
addTranslations(newTranslations);
addFeatures(newFeatures);
addDefaultFeatures(newDefaultFeatures);
addEventTypes(newEventTypes);
extendRoles(newRoles);

export default function App() {
    useStandardFonts();
    const url = useLiveUrl();
    const {siloKey, structureKey, instanceKey, screenStack} = getScreenStackForUrl(url);
    const structure = getStructureForKey(structureKey);

    console.log('App', {url, siloKey, structureKey, instanceKey, screenStack, structure});

    if (!structureKey) {
        return <UtilityText label='You need a valid space URL to see a space' />
    } else if (!structure) {
        return <UtilityText label='Unknown structure: {structureKey}' formatParams={{structureKey}} />
    } else if (!instanceKey) {
        return <UtilityText label='Missing instance key' />
    } else {
        return <ScreenStack url={url} screenStack={screenStack} siloKey={siloKey} structureKey={structureKey} instanceKey={instanceKey} />
    }
}
