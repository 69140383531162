import { HorizBox, Pad, Separator } from "np-platform-client/component/basics";
import { TextField, Heading } from "np-platform-client/component/text";
import { CTAButton, TextButton } from "np-platform-client/component/button";
import React, { useState, useEffect } from "react";
import { callServerApiAsync } from "np-platform-client/util/servercall";
import { useDatastore } from "np-platform-client/util/datastore";
import { View, StyleSheet, ScrollView, ActivityIndicator, Dimensions } from "react-native";
import { ArrowUpRight } from "@carbon/icons-react";
import { pushSubscreen } from "np-platform-client/util/navigate";
import { useIsAdmin } from "np-platform-client/component/admin";
import BlacklistList from './BlacklistList'; 
import { colorGreyPopupBackground, colorTextBlue, colorWhite } from "np-platform-client/component/color";
import { Modal } from "np-platform-client/component/modal";

export const BlacklistDashboardFeature = {
    name: 'Blacklist editor',
    key: 'blacklistdashboard',
    subscreens: {
        blacklist: () => <BlacklistScreen />
    },
    config: {
        pageTopWidgets: [openBlacklistButton],
    }
};

function openBlacklistButton() {
    const isAdmin = useIsAdmin();
    if (!isAdmin) {
        return null; 
    }

    const openBlacklist = () => {
        pushSubscreen('blacklist');
    };
    return (
        <HorizBox>
            <ArrowUpRight />
            <Pad size={5} />
            <TextButton
                label={"Blacklist"}
                type="small"
                underline={true}
                onPress={openBlacklist}
            />
        </HorizBox>
    );
}

const BlacklistScreen = () => {
    const datastore = useDatastore();
    const [newTerms, setNewTerms] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [blacklistData, setBlacklistData] = useState([]);
    const [selectedTerms, setSelectedTerms] = useState([]);
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState(null);
    const [feedbackMessage, setFeedbackMessage] = useState(null);
    const windowHeight = Dimensions.get('window').height;

    const maxHeight = windowHeight * 0.75;

    useEffect(() => {
        const fetchBlacklist = async () => {
            try {
                const blacklistResult = await datastore.callServerAsync('moderationZdf', 'getBlacklist', {});
                setBlacklistData(blacklistResult.map(term => term.toLowerCase()).sort());
            } catch (error) {
                console.error('Error fetching blacklist:', error);
                setErrorMessage('Error fetching blacklist data.');
            }
        };
        fetchBlacklist();
    }, []);

    const handleAddTerms = async (terms) => {
        const termsArray = terms.split(/\n/).flatMap(line => line.split('\t').map(term => term.trim()).filter(term => term !== ''));

        if (termsArray.length === 0) {
            setErrorMessage('Please enter at least one valid term.');
            return;
        }

        const uniqueTerms = Array.from(new Set(termsArray));
        const existingTerms = uniqueTerms.filter(term => blacklistData.includes(term));
        const newTerms = uniqueTerms.filter(term => !blacklistData.includes(term));

        if (existingTerms.length === termsArray.length) {
            setErrorMessage(`The following term(s) already exist in the blacklist.`);
            return;
        }

        if (newTerms.length === 0) {
            return;
        }

        setLoading(true);
        setLoadingText('Adding term(s)...');
        setFeedbackMessage(null);

        try {
            await callServerApiAsync({
                datastore,
                component: 'moderationZdf',
                funcname: 'addToBlacklist',
                params: { terms: newTerms } 
            });

            const updatedList = [...blacklistData, ...newTerms].sort();
            setBlacklistData(updatedList);
            setNewTerms('');
            setErrorMessage(null);
            setFeedbackMessage('Added term(s) successfully!');
            setLoadingText(null); 
        } catch (error) {
            setErrorMessage('An error occurred adding the terms.');
            setLoadingText(null); 
            console.error('API-Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSelectTerm = (term, index, shiftPressed) => {
        const isTermSelected = selectedTerms.includes(term);
        if (shiftPressed && lastSelectedIndex !== null) {
            const rangeStart = Math.min(lastSelectedIndex, index);
            const rangeEnd = Math.max(lastSelectedIndex, index);

            setSelectedTerms(prev => {
                const newSelectedTerms = new Set(prev);
                for (let i = rangeStart; i <= rangeEnd; i++) {
                    const termToSelect = blacklistData[i];
                    if (isTermSelected) {
                        newSelectedTerms.delete(termToSelect);
                    } else {
                        newSelectedTerms.add(termToSelect);
                    }
                }
                return Array.from(newSelectedTerms);
            });
        } else {
            setSelectedTerms(prev => {
                const newSelectedTerms = new Set(prev);
                if (isTermSelected) {
                    newSelectedTerms.delete(term);
                } else {
                    newSelectedTerms.add(term);
                }
                return Array.from(newSelectedTerms);
            });
        }
        setLastSelectedIndex(index);
        setFeedbackMessage(null);
    };

    const confirmDeleteSelected = () => {
        setShowDeleteConfirm(true);
    };

    const executeDelete = async () => {
        setLoading(true);
        setLoadingText('Deleting term(s)...');
        
        try {
            const updatedBlacklist = await callServerApiAsync({
                datastore,
                component: 'moderationZdf',
                funcname: 'removeFromBlacklist',
                params: { terms: selectedTerms } 
            });

            if (updatedBlacklist) {
                setBlacklistData(updatedBlacklist.sort());
            }

            setSelectedTerms([]);
            setShowDeleteConfirm(false);
            setFeedbackMessage('Deleted term(s) successfully!');
            setLoadingText(null); 
        } catch (error) {
            setErrorMessage('An error occurred while deleting selected terms. Some terms may not have been deleted.');
            setLoadingText(null); 
            console.error('API-Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <View style={{ flex: 1, paddingHorizontal: 10 }}>
            <View style={{ marginTop: 10 }}>
                <Heading
                    label="Blacklist Terms"
                    level={1} 
                    center={true} 
                />
            </View>

            <View style={{ marginTop: 10 }}>
                <Heading
                    label="Hold Shift and click to select or deselect multiple terms."
                    level={4} 
                    center={true} 
                />                
                <Pad size={10} />
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: "center" }}>
                    <View style={{ flexGrow: 1, marginRight: 10 }}>
                    <TextField
                        value={newTerms}
                        onChange={(text) => {
                            setNewTerms(text);
                            if (errorMessage) {
                                setErrorMessage(null);
                            }
                            setFeedbackMessage(null);
                        }}
                        placeholder="Enter new terms, separated by new lines"
                        multiline
                        editable={!loading}
                    />

                    </View>
                    <View>
                        <CTAButton
                            label="Add to blacklist"
                            onPress={() => handleAddTerms(newTerms)}
                            disabled={loading}
                        />
                    </View>
                </View>
            </View>

            <Separator />

            <View style={{ flex: 1 }}>
                {loading && (
                    <View style={styles.loadingContainer}>
                        <ActivityIndicator size="small" color={colorTextBlue} />
                        <Heading
                            label={loadingText}
                            level={4}
                            center={false}
                            color="blue"  
                        />
                    </View>
                )}
                
                {feedbackMessage !== null && (
                    <Heading
                        label={feedbackMessage}
                        level={4}
                        center={false}
                        color="green"
                    />
                )}
                {feedbackMessage !== null ? (
                    <Heading
                        label={errorMessage}
                        level={4}
                        center={false}
                        color="red"
                    />
                ) : null}

                <ScrollView style={{ maxHeight: maxHeight, flexGrow: 1 }}>
                    <BlacklistList
                        data={blacklistData}
                        selectedTerms={selectedTerms}
                        onSelectTerm={handleSelectTerm}
                    />
                </ScrollView>
            </View>

            <View style={styles.actionButtonsContainer}>
                {selectedTerms.length > 0 && (
                    <View style={{ marginTop: 20 }}> 
                        <CTAButton label="Delete Selected" onPress={confirmDeleteSelected} />
                    </View>
                )}
            </View>

            {showDeleteConfirm && (
                <Modal onClose={() => setShowDeleteConfirm(false)}
                    buttonRow={<View style={styles.modalButtonContainer}>
                        <CTAButton
                            type="delete"
                            label="Cancel"
                            onPress={() => setShowDeleteConfirm(false)}
                        />
                        <CTAButton
                            label="Delete"
                            onPress={executeDelete}
                        />
                    </View>}>
                    <Heading
                        label="Delete Terms?"
                        level={2}
                        center={true}
                    />
                    <Pad size={16}/>
                    <Heading
                        label="Do you really want to delete the selected term(s)? This action cannot be undone."
                        level={4}
                        center={true}
                    />
                </Modal>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    loadingContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10,
    },
    modalButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    actionButtonsContainer: {
        paddingVertical: 10,
        paddingHorizontal: 10,
        justifyContent: 'flex-end',
        marginBottom: 20, 
    },
});

export default BlacklistScreen;
