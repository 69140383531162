import { View } from "react-native-web";
import { HorizBox, Pad, PadBox } from "np-platform-client/component/basics";
import { UtilityText } from "np-platform-client/component/text";
import { colorBlack, colorGreyBorder, colorPurpleBackground, colorTextBlue, colorWhite } from 'np-platform-client/component/color';
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Animated, StyleSheet, PanResponder, Pressable, Easing } from 'react-native';

export const EstimationSliderFeature = {
    name: 'Estimation Slider',
    key: 'estimationslider',
    config: {}
}

// Problem is the coupled xPosition to the image animation, maybe a sperate wrapper component can enable seperation
export function EstimationSlider({ min, max, stepSize, value, onChangeValue, trailingSymbol = "€", AnimationComponent, snapOnMove=false }) {
    const s = SliderWithStopPointsStyle;

    const [isDragging, setIsDragging] = useState(false)

    const [componentWidth, setComponentWidth] = useState(null);
    const xPosition = useRef(new Animated.Value(0)).current;
    const [hasLayout, setHasLayout] = useState(false);
    const barRef = useRef(null);

    const [hoverValue, setHoverValue] = useState(value!==undefined ? value : min);
    const numberOfStops = (max - min)/stepSize+1; // +1 to include max
    const previousValue = useRef(value)

    const [showAnimation, setShowAnimation] = useState(false)
    // unique key to make it possible to rerender animations without changing props
    const [animationKey, setAnimationKey] = useState(0);
    
    function getSpaceBetweenStops() {
        const rect = barRef.current.getBoundingClientRect();
        return (rect.width) / (numberOfStops - 1);
    }
    /**Calculates the nearest "stop" for a value */
    function valueToXPosition(value) {
        const stopWidth = getSpaceBetweenStops();
        return Math.round((value - min)/stepSize) * stopWidth;
    }

    /**Calculates the distance of the current position to the left side of the bar */
    function screenXPositionXOffset(xPosition) {
        const rect = barRef.current.getBoundingClientRect();
        return xPosition - rect.left;
    }

    /**Converts x Position to the clostes stop value*/
    function xPositionToValue(xPosition) {
        const stopWidth = getSpaceBetweenStops();
        const unclippedValue = (Math.round((xPosition) / stopWidth));
        return Math.max(min, Math.min(max, unclippedValue))*stepSize + min;

    }

    useEffect(() => {
        setHoverValue(value)
        previousValue.current = value;
    }, [value]);

    const panResponder = PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onMoveShouldSetPanResponder: () => true,
        onPanResponderTerminate: () => {
            setIsDragging(false)
        },
        onPanResponderGrant: () => {
            xPosition.flattenOffset();
        },
        onPanResponderMove: (_, gestureState) => {
            setIsDragging(true)
            setShowAnimation(false)
            const dragX = screenXPositionXOffset(gestureState.moveX);
            const rect = barRef.current.getBoundingClientRect();
            const clippedX = Math.max(0, Math.min(rect.width, dragX));
            const newHoverValue = xPositionToValue(clippedX);
            snapOnMove ? xPosition.setValue(valueToXPosition(newHoverValue)) : xPosition.setValue(clippedX)
            setHoverValue(newHoverValue);

        },
        onPanResponderRelease: (_, gestureState) => {
            setIsDragging(false)
            setShowAnimation(true)
            const pressX = screenXPositionXOffset(gestureState.moveX);
            const rect = barRef.current.getBoundingClientRect();
            const clippedX = Math.max(0, Math.min(rect.width, pressX));
            const newValue = xPositionToValue(clippedX);
            const newXPosition = valueToXPosition(newValue);
            xPosition.setValue(clippedX);

            Animated.parallel([
                Animated.timing(xPosition, {toValue: newXPosition, duration: 1, useNativeDriver: false}),

            ],{stopTogether:false}).start(()=>{
            }),
                onChangeValue(newValue);},
    })

    const handleBarPress = (e) => {
        if (componentWidth > 0 && barRef.current) {
            const pressX = screenXPositionXOffset(e.nativeEvent.pageX);
            const newValue = xPositionToValue(pressX);
            const newXPosition = valueToXPosition(newValue)
            xPosition.setValue(newXPosition)
            setHoverValue(newValue);
            setShowAnimation(true);
            setAnimationKey(animationKey + 1)
            onChangeValue(newValue);
        }
    };

    /**Adjusts the current x position value based on the current width */
    const handleLayout = (event) => {
        const { width } = event.nativeEvent.layout;
        setComponentWidth(width);
        xPosition.setValue(valueToXPosition(hoverValue))
        setHasLayout(true);
    };
    return (
        <View style={{userSelect: isDragging ? "none" : "auto"}}>
            <View>
                <Pad size={12} />
                <View style={s.overlapBar}>
                    <HoverHelpBar label={hoverValue.toString()}
                        trailingSymbol={trailingSymbol}
                        xPosition={xPosition} />
                </View>
            </View>
            <Pressable ref={barRef} onPress={handleBarPress}>
                <View style={s.container} onLayout={handleLayout}>
                    {hasLayout && <Animated.View style={[s.coloredContainer, { width: xPosition._value < 0 ? "0px": xPosition }]}>
                        {hasLayout && <Animated.View
                            {...panResponder.panHandlers}
                            style={[s.dragHandle,
                            {left:xPosition},
                            (s.dragHandleShadow),
                            ]}
                        >
                        <View style={s.animationContainer}>
                           { (AnimationComponent && showAnimation) && <AnimationComponent key={animationKey}></AnimationComponent>}
                        </View>
                        </Animated.View>}
                    </Animated.View>}
                </View>
            </Pressable>
            <HorizBox spread>
                <UtilityText text={min.toString()}></UtilityText>
                <UtilityText text={max.toString()}></UtilityText>
            </HorizBox>
        </View>
    );
};

const SliderWithStopPointsStyle = StyleSheet.create({
    container: {
        width: '100%',
        height: 15,
        borderRadius: 4,
        marginVertical: 16,
        backgroundColor: colorGreyBorder,
        justifyContent: 'center',
    },
    image: {
        width: 125,
        height: 125,
        position: "absolute",
        zIndex: -1,
        transformOrigin: "bottom left"
    },
    animationContainer: {
        pointerEvents: "none"
    },
    coloredContainer: {
        width: '0%',
        height: 15,
        borderRadius: 4,
        backgroundColor: colorBlack,
        justifyContent: "center",
        zIndex: -1,
        position: "relative"
    },
    overlapBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 8,
    },
    dragHandle: {
        width: 25,
        height: 25,
        borderRadius: 16,
        backgroundColor: colorTextBlue,
        position: 'absolute',
        borderColor: colorGreyBorder,
        borderWidth: 1,
        cursor: 'pointer',
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        transform: "translateX(-50%)",
        borderWidth: 2
    },
    dragHandleShadow: {
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.30)',
    },
    selectionStopPoint: {
        width: 6,
        height: 6,
        borderRadius: 0,
    },
});

function HoverHelpBar({ label, xPosition, trailingSymbol }) {
    const s = HoverHelpBarStyle;
    return <View style={s.outer}>
        <Animated.View style={[s.hoverPill, { left: xPosition }]}>
            <UtilityText strong type='small' label={label + trailingSymbol} color={colorBlack} />
        </Animated.View>
    </View>
}

const HoverHelpBarStyle = StyleSheet.create({
    label: {
        color: colorWhite,
    },
    outer: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    hoverPill: {
        backgroundColor: colorWhite,
        flexDirection: 'row',
        alignItems: 'center',
        transform: [{translateX: "-50%"}],
        flexGrow: 0,
        flexShrink: 1,
        alignSelf: "baseline",
    }
})