import { DataVizText, Heading, Paragraph, UtilityText } from "np-platform-client/component/text";
import { useCollection, useDatastore, useInstanceKey, useStructureKey, useGlobalProperty } from "np-platform-client/util/datastore";
import { useEffect, useRef, useState } from "react";
import { logEventAsync } from "np-platform-client/util/eventlog";
import { StyleSheet, View } from "react-native";
import { HorizBox, Narrow, Pad, PadBox, Separator, WindowTitle } from "np-platform-client/component/basics";
import { colorLightBlueBackground, colorGreen, colorPinkBackground, colorRed, colorTextGrey, colorWhite, colorGreyBorder } from "np-platform-client/component/color";
import { Tag, CTAButton, IconButton, TextButton } from "np-platform-client/component/button";
import { formatDate } from "np-platform-client/component/date";
import { useLanguage } from "np-platform-client/component/translation";
import { useIsAdmin } from "np-platform-client/component/admin";
import { gotoInstance } from "np-platform-client/util/navigate";
import { TabBar, TabContent, TabNavigation } from "../../contrib/zdf/tabnavigation";
import { FaceImage } from "np-platform-client/component/people";
import { AutoModeratorReactionTypesWidget, countReactionsPerType } from "../../component/zdf/conversationhelper/autoModeratorReactions";
import { ScrollView } from "react-native-web";
import { Banner } from "np-platform-client/component/banner";
import { useConfig } from "np-platform-client/util/features";
import { SpacedArray } from "np-platform-client/component/demo";
import { imagesZdf } from "../../assets/zdf";
import { RichText } from "np-platform-client/component/richtext";
import { ArrowUpRight, Time } from "@carbon/icons-react";
import { SECTION } from "np-platform-client/feature";
import { PrivateCommentFeedbackFeature } from "./PrivateCommentFeedbackFeature";
import { AccordionField } from "np-platform-client/component/form";
import { REPLACE_ZDF_AccordionField } from "../../component/zdf/form";
import { useTypewriter } from "../../component/zdf/typewriter";

/*
Figma: https://www.figma.com/design/VDCebWSu7lU0GynG1qmtF5/Conversation-Helper---Testing?node-id=0-1&t=VlCGmpdSrRKAKHst-0

The Conversation Helper is an auto-moderation feature that aims to steer and guide the conversation by providing public, thought-provoking prompts. They are posted in a widget above the conversation and are visible to everyone. The Conversation Helper's guidance is twofold: it highlights interesting and new aspects for users to discuss and shares a reminder about how to improve the conversation.

The first thought-provoking prompt is generated automatically when turning this feature on. It is always based on the topic of the conversation and disregards existing comments if there are any.

After generating the initial thought-provoking prompt, the following process may be triggered every time a new comment is posted or an existing comment is updated:
1. Look at all comments and detect problems in the conversation.
2. If problems are found, generate a new thought-provoking prompt that relates to the problems detected. If no problems are found, keep the current thought-provoking prompt.

The analysis frequency can be adjusted in the feature menu. For example, you can run the analysis after every new comment posted, or only when 5 new comments have been posted.

Additional sub-features:
- Reactions: users can rate the current thought-provoking prompt and reminder with various reaction types
- Conversation summary: summarizes the comments whenver a new comment is posted
- Help history: show a history of all previous reminders (currently doesn't include the thought-provoking questions)
*/

export const ConversationHelperFeature = {
    name: 'Conversation Helper',
    key: 'conversationhelper',
    subscreens: {
        prompthistory: () => <ThoughtProvokingPromptHistory />,
        conversationhelperadmin: () => <ConversationHelperAdminScreen />
    },
    config: {
        pageTopWidgets: [ThoughtProvokingPromptSelector, ConversationHelperAdminButton],
        commentPostTriggers: [checkConversationAsync, summarizeDiscussion],
        commentInputPlaceholder: 'Share your thoughts...',
        commentAllowEmpty: false,
    },
    defaultConfig: {
        reactionsEnabled: false,
        reactionTypes: [
            { label: 'Thank you', emoji: '🙏' },
            { label: 'New to me', emoji: '💡' },
            { label: 'Respect', emoji: '🤝🏽' },
            { label: 'Disagree', emoji: '🤔' },
        ],
        historyEnabled: false,
        summaryEnabled: false,
        commentBelowWidgets: [],
        timingSetting: {
            type: "comment",
            every: 1
        },
        conversationHelperAppearance: {
            name: "Auto-Moderator",
            photoUrl: imagesZdf.conversationhelperProfileBulb
        },
        moderatorType: "a",
        analysisScope: "allComments",
        minCommentThreshold: 1,
        topXPercentFloat: 0.1,
        guidanceUpdateIntervalEnabled: true,
        guidanceUpdateCommentInterval: null,
    },
}

export const ConversationHelperReactionsFeature = {
    name: 'Reactions',
    parentFeature: 'conversationhelper',
    key: 'conversationhelper_reactions',
    config: {
        reactionsEnabled: true
    }
};

export const ConversationHelperHistoryFeature = {
    name: 'Help History',
    parentFeature: 'conversationhelper',
    key: 'conversationhelper_history',
    config: {
        historyEnabled: true
    }
};

export const ConversationHelperSummaryFeature = {
    name: 'Conversation Summary',
    parentFeature: 'conversationhelper',
    key: 'conversationhelper_summary',
    config: {
        summaryEnabled: true
    }
}

export const ConversationHelperAddonFeatures = [
    SECTION("Add-ons", [
        ConversationHelperReactionsFeature,
        ConversationHelperHistoryFeature,
        ConversationHelperSummaryFeature,
        PrivateCommentFeedbackFeature,
    ]),
];

/** Selects the most recent thought-provoking prompt */
export function ThoughtProvokingPromptSelector() {
    const datastore = useDatastore();

    // Get the latest prompt first
    const thoughtProvokingPrompts = useCollection("thought_provoking_prompt", { sortBy: 'time', reverse: true });
    const thoughtProvokingPromptExists = (thoughtProvokingPrompts && thoughtProvokingPrompts.length > 0);

    useEffect(() => {
        // If there is no thought-provoking prompt yet, generate one based on the conversation topic
        if (!thoughtProvokingPromptExists) {
            generateThoughtProvokingPromptFromTopicAsync({ datastore });
        }
    }, []);

    return (
        <View>
            {thoughtProvokingPromptExists && (
                <ThoughtProvokingPrompt
                    currentThoughtProvokingPrompt={thoughtProvokingPrompts[0]}
                    allThoughtProvokingPrompts={thoughtProvokingPrompts}
                    showAuthor={true}
                    textIsAnimated={true}
                />
            )}
        </View>
    );
}

/** Displays a thought-provoking prompt in a widget */
export function ThoughtProvokingPrompt({ currentThoughtProvokingPrompt, textIsAnimated = false }) {
    const s = ThoughtProvokingPromptStyle;
    const firstThoughProvokingPromptKey = useRef(null);

    const datastore = useDatastore();
    const language = useLanguage();
    let config = useConfig();

    const [showHistory, setShowHistory] = useState(false);
    const [hasClicked, setHasClicked] = useState(false);

    const summary = useCollection("summary", { sortBy: "time", reverse: true });
    const hasSummary = summary && summary.length > 0;
    const newestSummary = summary[0];


    if (config.conversationHelperAppearance === undefined) {
        config = {
            ...config,
            conversationHelperAppearance: {
                name: "Auto-Moderator",
                photoUrl: imagesZdf.conversationhelperProfileBulb,
            },
        };
    }

    useEffect(() => {
        if (hasClicked)
            logEventAsync(
                datastore,
                showHistory ? "conversationhelper-view-history" : "conversationhelper-close-history"
            );
    }, [hasClicked, showHistory]);

    useEffect(()=>{
        if(!firstThoughProvokingPromptKey.current) firstThoughProvokingPromptKey.current = currentThoughtProvokingPrompt.key
    }, [currentThoughtProvokingPrompt])
    const firstRenderedPrompt = !firstThoughProvokingPromptKey.current || currentThoughtProvokingPrompt.key === firstThoughProvokingPromptKey.current

    const typeWriterReminder = useTypewriter({
        text: currentThoughtProvokingPrompt.reminder,
        play: false,
        timePerCharacterInMs: (textIsAnimated && !firstRenderedPrompt) ? 10 : 0,
    })
 
    const typeWriterSuggestedPerspective = useTypewriter({
        play: true,
        text: currentThoughtProvokingPrompt.suggestedPerspective,
        timePerCharacterInMs:  (textIsAnimated && !firstRenderedPrompt) ? 10 : 0,
        nextTypewriter: typeWriterReminder
    })

    return (
        <View style={[s.coloredBackground]}>
            <PadBox horiz={20} vert={20}>
                <HorizBox center spread>
                    <HorizBox center>
                        <FaceImage photoUrl={config.conversationHelperAppearance.photoUrl} />
                        <Pad size={10} />
                        <Heading center level={1} label={"Hello everyone!"} />
                        <Pad size={10} />
                        <View>
                            <UtilityText
                                color={colorTextGrey}
                                label={"{time}"}
                                type="tiny"
                                formatParams={{
                                    time: formatDate(currentThoughtProvokingPrompt.time, language),
                                }}
                            />
                        </View>
                    </HorizBox>
                    {config.historyEnabled && (
                        <HorizBox right center>
                            <Time />
                            <Pad size={5} />
                            <TextButton
                                label={"Help history"}
                                type="small"
                                underline={false}
                                onPress={() => {
                                    setHasClicked(true);
                                    setShowHistory(!showHistory);
                                    datastore.pushSubscreen("prompthistory");
                                }}
                            />
                        </HorizBox>
                    )}
                </HorizBox>
                <Pad />
                <View>
                    <Heading
                        level={2}
                        text={typeWriterSuggestedPerspective.displayText}
                    />
                    <Pad size={8} />
                    <Paragraph
                        type="small"
                        text={typeWriterReminder.displayText}
                    />
                </View>
                {config.reactionsEnabled && (
                    <AutoModeratorReactionTypesWidget thoughtProvokingPrompt={currentThoughtProvokingPrompt} />
                )}
                {config.summaryEnabled && hasSummary && (
                    <View style={{ marginBottom: -16, marginHorizontal: -16 }}>
                        <Pad size={10} />
                        <REPLACE_ZDF_AccordionField
                            nameInLog="Conversation Summary"
                            titleContent={
                                <PadBox left={16}>
                                    <UtilityText strong label={"Conversation Summary"} />
                                </PadBox>
                            }
                        >
                            <View>
                                <PadBox horiz={16}>
                                    <Separator />
                                </PadBox>
                                <PadBox top={16} left={16} right={16 * 3} bottom={16}>
                                    <Paragraph text={newestSummary.summary} />
                                </PadBox>
                            </View>
                        </REPLACE_ZDF_AccordionField>
                    </View>
                )}
            </PadBox>
            <Separator />
        </View>
    );
}

const ThoughtProvokingPromptStyle = StyleSheet.create({
    coloredBackground: {
        backgroundColor: colorLightBlueBackground
    },
    historyTitleBackgroundColor: {
        backgroundColor: colorPinkBackground
    }
})

/** Displays the history of all thought-provoking prompts */
export function ThoughtProvokingPromptHistory() {
    const s = ThoughtProvokingPromptStyle;
    const allThoughtProvokingPrompts = useCollection("thought_provoking_prompt", { reverse: true })
    const historyAvailable = allThoughtProvokingPrompts && allThoughtProvokingPrompts.length > 0;

    return (
        <ScrollView>
            <View>
                <Banner color={s.historyTitleBackgroundColor.backgroundColor}>
                    <PadBox vert={20} horiz={10}>
                        <Heading level={2} label={"Help history"} />
                    </PadBox>
                </Banner>
                <Pad size={10} />
                <PadBox vert={0}>
                    {!historyAvailable ? (
                        <UtilityText label={"No history available."} />
                    ) : (
                        allThoughtProvokingPrompts.map((thoughtProvokingPrompt) => (
                            <View key={"prompt-" + thoughtProvokingPrompt.key}>
                                <ThoughtProvokingPromptHistoryItem thoughtProvokingPrompt={thoughtProvokingPrompt} />
                            </View>
                        ))
                    )}
                </PadBox>
            </View>
        </ScrollView>
    );
}

/** A thought-provoking prompt as displayed in the history */
export function ThoughtProvokingPromptHistoryItem({ thoughtProvokingPrompt }) {
    const language = useLanguage();
    const config = useConfig();

    const reactions = useCollection('thought_provoking_prompt_reactions', { filter: { reactTo: thoughtProvokingPrompt.key } });
    const hasReactions = reactions && reactions.length > 0;
    const countedReactionsPerType = countReactionsPerType(reactions);
    const reactionMap = config.reactionTypes;

    // Filter reactions by type so we only list each type once
    const uniqueReactions = reactions.filter((reaction, index, self) => {
        return self.findIndex(r => r.reactionType === reaction.reactionType) === index;
    })

    return (
        <View>
            <PadBox vert={10} horiz={20}>
                <DataVizText
                    type={"heading2"}
                    label={"{time}"}
                    formatParams={{ time: formatDate(thoughtProvokingPrompt.time, language) }}
                />
                <PadBox vert={10} />
                <UtilityText strong text={thoughtProvokingPrompt.suggestedPerspective}/>
                <PadBox vert={6}/>
                <UtilityText text={thoughtProvokingPrompt.reminder} />
                <PadBox />

                {config.reactionsEnabled && hasReactions ? (
                    <HorizBox>
                        {uniqueReactions.map((reaction, id) => (
                            <PadBox key={"padding-" + id} vert={15} right={5}>
                                <Tag
                                    key={reaction.reactionType}
                                    label={countedReactionsPerType[reaction.reactionType].toString()}
                                    emoji={
                                        reactionMap.filter((entry) => entry.label === reaction.reactionType)[0].emoji
                                    }
                                />
                            </PadBox>
                        ))}
                    </HorizBox>
                ) : (
                    <Pad size={20} />
                )}
                <Separator />
            </PadBox>
        </View>
    );
}

/** This is called every time a comment is posted. Based on the configured analysis frequency, it checks if there are problems in the conversation and generates a new thought-provoking prompt. */
async function checkConversationAsync({ datastore }) {

    const timingSetting = datastore.getConfig().timingSetting;
    const comments = datastore.getCollection("comment", { sortBy: "time", reverse: true });

    if (timingSetting.type === "manual") {
        return;
    } else if (timingSetting.type === "comment") {
        let lastAnalysisTime = datastore.getGlobalProperty("lastConversationAnalysisTime");
        if (!lastAnalysisTime) lastAnalysisTime = 0;

        const newCommentsSinceLastAnalysis = getNewCommentsSinceTime({ comments, timestamp: lastAnalysisTime });
        if (newCommentsSinceLastAnalysis.length < timingSetting.every) {
            return;
        }
    }

    const commentsToAnalyze = getCommentsToAnalyzeAsJsonString({ datastore });

    const problemsResult = await checkForProblemsInConversationAsync({
        datastore,
        comments: commentsToAnalyze,
        triggerType: "automatic",
    });

    if (problemsResult.problemsFound) {
        generateThoughtProvokingPromptFromConversationAsync({
            datastore,
            comments: commentsToAnalyze,
            problemsExplanation: problemsResult.explanation,
            triggeredBy: "problems"
        });
    } else if (problemsResult.problemsFound === false) {
        updateThoughtProvokingPromptWithoutProblems({datastore});
    }
}

async function updateThoughtProvokingPromptWithoutProblems({datastore}) {
    const config = datastore.getConfig();

    if (!config.guidanceUpdateIntervalEnabled || !config.guidanceUpdateCommentInterval) {
        return;
    }

    const comments = datastore.getCollection("comment", { sortBy: "time", reverse: true });
    let lastGuidanceUpdateTime = datastore.getGlobalProperty("lastGuidanceUpdateTime"); 

    if (lastGuidanceUpdateTime === null || lastGuidanceUpdateTime === undefined) {
        lastGuidanceUpdateTime = 0;
    }
    // console.log("Last guidance update", lastGuidanceUpdateTime, new Date(lastGuidanceUpdateTime));

    const commentsSinceLastGuidanceUpdate = getNewCommentsSinceTime({
        comments: comments,
        timestamp: lastGuidanceUpdateTime,
    });
    // console.log("Comments since last guidance update: ", commentsSinceLastGuidanceUpdate);

    if (config.guidanceUpdateCommentInterval) {
        // console.log("Guidance comment interval", config.guidanceUpdateCommentInterval);

        if (commentsSinceLastGuidanceUpdate.length >= config.guidanceUpdateCommentInterval) {
            generateThoughtProvokingPromptFromConversationAsync({
                datastore,
                comments: commentsSinceLastGuidanceUpdate,
                problemsExplanation: "No problems found",
                triggeredBy: "comment threshold for guidance update"
            });
        }
    }
    
}

/** Returns the comments that are relevant to the conversation analysis based on the configured scope */
function getCommentsToAnalyzeAsJsonString({ datastore }) {
    const comments = datastore.getCollection("comment",{});
    const config = datastore.getConfig();

    let cleanedComments = [];

    if (config.analysisScope === "allComments") {
        cleanedComments = getCommentsWithoutUnnecessaryInfo({ comments: comments });
    }
    else if (config.analysisScope === "selectTopXPercent") {
        const sortedComments = sortCommentsByEditedAndTime({ comments });
        const recentComments = getRecentCommentsByPercentage({
            sortedComments: sortedComments,
            minCommentThreshold: config.minCommentThreshold ?? 1,
            topXPercentFloat: config.topXPercentFloat??0.1
        });
        cleanedComments = getCommentsWithoutUnnecessaryInfo({ comments: recentComments });
    }
    else if (config.analysisScope === "newCommentsSinceLastAnalysis") {
        const lastAnalysisTime = datastore.getGlobalProperty("lastConversationAnalysisTime");
        const commentsSinceLastAnalysis = getNewCommentsSinceTime({
            comments: comments,
            timestamp: lastAnalysisTime,
        });
        cleanedComments = getCommentsWithoutUnnecessaryInfo({ comments: commentsSinceLastAnalysis });
    }

    const commentsToAnalyze = cleanedComments.length === 0 ? "No comments to analyze." : JSON.stringify(cleanedComments);
    return commentsToAnalyze;
}

/** Sorts comments according to when they were last updated, prioritizing the value of `edited` if it exists, otherwise `time` */
function sortCommentsByEditedAndTime({ comments }) {
    // Compare times of two comments and sort in ascending order
    const sortedComments = comments.sort((a, b) => {
        if (a.edited && b.edited) {
            return a.edited - b.edited;
        } else if (a.edited && b.time) {
            return a.edited - b.time;
        } else if (a.time && b.edited) {
            return a.time - b.edited;
        } else {
            return a.time - b.time;
        }
    });
    // Descending order with newest comments at the top
    sortedComments.reverse();

    return sortedComments;
}

function getNewCommentsSinceTime({ comments, timestamp }) {
    const commentsSinceLastAnalysis = comments.filter((comment) => {
        return (comment.time > timestamp || comment.edited > timestamp);
    })
    return commentsSinceLastAnalysis;
}

/**
 * Returns recent comments based on a given percentage 
 * @param {Array} sortedComments - An array of comments sorted by their time
 * @param {number} minCommentThreshold - The amount of comments needed until percentage is applied and older comments are ignored
 * @param {float} topXPercentFloat - How many percent of the comments should be used
 * @returns {Array}
 */
function getRecentCommentsByPercentage({ sortedComments, minCommentThreshold = 1, topXPercentFloat = 0.1 }) {
    const numberOfComments = sortedComments.length;

    if (!sortedComments || numberOfComments === 0 || numberOfComments < minCommentThreshold) {
        return sortedComments;
    }

    if (topXPercentFloat > 1.0) topXPercentFloat = 1.0;

    // Rounding up to the next integer so there's always at least one comment to analyze
    let endIndex = Math.ceil(numberOfComments * topXPercentFloat);

    if (endIndex > sortedComments.length) {
        endIndex = sortedComments.length;
    }

    const newestComments = sortedComments.slice(0, endIndex);

    return newestComments;
}

/** Deletes all unnecessary info from a comment array except for `key`, `text` and `replyTo` */
function getCommentsWithoutUnnecessaryInfo({ comments }) {
    let minimalComments = [];
    comments.map((comment) => {
        let minimalComment = { key: comment.key, text: comment.text };
        if (comment.replyTo) {
            minimalComment.replyTo = comment.replyTo;
        }
        minimalComments.push(minimalComment);
    });
    return minimalComments;
}

// TODO: Consider removing this if we are sure we always want to send the comments as a JSON string instead of merging only the text fields
function mergeTextOfAllComments({ datastore, comment }) {
    const comments = datastore.getCollection("comment", { sortBy: "time", reverse: true });

    // Merge all comments into a string. If the current comment is an edit, filter out the previous version of it.
    let mergedComments = comments
        .filter((c) => c.key !== comment?.key)
        .map((c) => c.text)
        .join("\n\n");

    // Add the current version of this comment. Only necessary if it is an edit.
    if (comment && comment.key !== undefined) {
        mergedComments = mergedComments === "" ? comment.text : comment.text + "\n\n" + mergedComments;
    }
    return mergedComments;
}

async function generateThoughtProvokingPromptFromTopicAsync({ datastore }) {
    const topic = datastore.getGlobalProperty("name");

    const thoughtProvokingPromptResult = await datastore.callServerAsync(
        'conversationhelper', 'generateThoughtProvokingPromptFromTopic', {topic});

    await datastore.callServerAsync('conversationhelper', 'setLastGuidanceUpdateTime', {});
    
    logEventAsync(datastore, "conversationhelper-update-guidance", {
        generatedFrom: "topic",
        suggestedPerspective: thoughtProvokingPromptResult.suggestedPerspective,
        reminder: thoughtProvokingPromptResult.reminder,
        triggeredBy: "initilization",
        // There are no different personalities for generating guidance based on the topic alone.
        personalityUsed: "default"
    });

}

async function checkForProblemsInConversationAsync({ datastore, comments, triggerType = "automatic" }) {
    const topic = datastore.getGlobalProperty("name");
    const config = datastore.getConfig();

    const problemsResult = await datastore.callServerAsync('conversationhelper', 'checkForProblemsInConversation', {
        comments: comments,
        topic,
        moderatorType: config.moderatorType
    });

    logEventAsync(datastore, "conversationhelper-analyze-conversation", {
        problemsFound: problemsResult.problemsFound.toString() ?? "false",
        explanation: problemsResult.explanation,
        triggeredBy: triggerType === "automatic" ? "automation as configured" : "admin",
        timingOfAnalysis: "every " + config.timingSetting.every + " " + config.timingSetting.type + "(s)",
        scopeOfAnalysis:
            config.analysisScope === "selectTopXPercent"
                ? "most recent x% of comments"
                : config.analysisScope,
        scopeOfAnalysisPercentage:
            config.analysisScope === "selectTopXPercent"
                ? (config.topXPercentFloat * 100) + "%"
                : "not applicable",
        scopeOfAnalysisThreshold:
            config.analysisScope === "selectTopXPercent"
                ? config.minCommentThreshold + " comment(s)"
                : "not applicable",
    });

    return problemsResult;
}

async function generateThoughtProvokingPromptFromConversationAsync({ datastore, comments, problemsExplanation, triggeredBy = "" }) {
    const topic = datastore.getGlobalProperty("name");
    const moderatorType = datastore.getConfig().moderatorType

    const thoughtProvokingPromptResult = await datastore.callServerAsync(
        'conversationhelper', 'generateThoughtProvokingPromptFromConversation', {
            comments: comments,
            analysis: problemsExplanation,
            topic,
            moderatorType
        }
    );

    await datastore.callServerAsync('conversationhelper', 'setLastGuidanceUpdateTime');

    logEventAsync(datastore, "conversationhelper-update-guidance", {
        generatedFrom: "conversation",
        suggestedPerspective: thoughtProvokingPromptResult.suggestedPerspective,
        reminder: thoughtProvokingPromptResult.reminder,
        triggeredBy: triggeredBy,
        personalityUsed: datastore.getConfig().moderatorType
    });
}

// This function is only a temp. solution until a scheduling system is in place
function summarizeDiscussion({ datastore }) {
    const config = datastore.getConfig()
    if (config.summaryEnabled) {
        const comments = datastore.getCollection("comment", { sortBy: "time", reverse: true });
        const hasComments = comments && comments.length > 0;

        if (!hasComments) { return; }

        const minimalComments = getCommentsWithoutUnnecessaryInfo({ comments: comments });
        // console.log("Generating conversation summary from:", minimalComments);
        generateSummaryFromConversationAsync({ datastore, comments: minimalComments, triggeredBy: "new comment" });
    }
}

async function generateSummaryFromConversationAsync({ datastore, comments, triggeredBy = "" }) {
    const topic = datastore.getGlobalProperty("name");
    const commentsJsonString = JSON.stringify(comments);

    const summaryResult = await datastore.callServerAsync(
        'conversationhelper', 'generateSummaryFromConversation', {
            topic: topic,
            comments: commentsJsonString
        },
    );

    logEventAsync(datastore, "conversationhelper-generate-summary", {
        summary: summaryResult.summary,
        // There is only one version of the summary prompt
        personalityUsed: "default",
        triggeredBy
    });
}

function ConversationHelperAdminButton() {
    const isAdmin = useIsAdmin()
    const datastore = useDatastore()
    const openDashboard = () => {
        datastore.pushSubscreen('conversationhelperadmin');
    }

    if (!isAdmin) return null;

    return (
        <View style={{ position: "absolute", zIndex: 10, right: 20 }}>
            <TextButton type={"tiny"} label={"Open dashboard (admin)"} color={colorTextGrey} onPress={openDashboard} />
        </View>
    );
}

export function ConversationHelperAdminScreen() {
    const questionName = useGlobalProperty("name");
    const structureKey = useStructureKey();
    const instanceKey = useInstanceKey();
    const isAdmin = useIsAdmin();

    const tabs = [
        {
            label: "Conversation Helper Info",
            id: "stats",
            component: ConversationHelperAdminOverview
        }
    ]

    if (!isAdmin) {
        return <UtilityText text={"Access denied"} />
    }

    return (
        <PadBox horiz={24}>
            <Narrow>
                <WindowTitle title="Conversation Helper Dashboard" />
                <Heading level={1} label="Conversation Helper Dashboard" />

                <Pad />

                <Heading label={"Related Thread"} />
                <HorizBox center>
                    <TextButton
                        label={questionName}
                        editorial
                        type="small"
                        onPress={() => gotoInstance({ structureKey: structureKey, instanceKey: instanceKey })}
                    />
                    <IconButton
                        icon={ArrowUpRight}
                        onPress={() => gotoInstance({ structureKey: structureKey, instanceKey: instanceKey })}
                    />
                </HorizBox>

                <Pad />
                <TabNavigation tabs={tabs}>
                    <TabBar />
                    <TabContent />
                </TabNavigation>
            </Narrow>
        </PadBox>
    );
}

function ConversationHelperAdminOverview() {
    const datastore = useDatastore();
    const config = useConfig();
    const language = useLanguage();

    const thoughtProvokingPrompts = useCollection("thought_provoking_prompt", { sortBy: "time", reverse: true });
    const comments = useCollection("comment", { sortBy: "time", reverse: true });
    const allAnalysisResults = useCollection("conversation_analysis_result", { sortBy: "time", reverse: true });

    const lastAnalysisTime = useGlobalProperty("lastConversationAnalysisTime");
    const lastAnalysisTimeFormatted = formatDate(lastAnalysisTime, language);
    const latestThoughtProvokingPrompt = thoughtProvokingPrompts[0];
    const filteredComments = comments.filter((c) => c.time > lastAnalysisTime || c.edited > lastAnalysisTime);

    const [manualAnalysisResult, setManualAnalysisResult] = useState(null);

    const performManualCheckForProblems = async () => {
        let commentsToAnalyze = getCommentsToAnalyzeAsJsonString({ datastore });

        const problemsResult = await checkForProblemsInConversationAsync({
            datastore,
            comments: commentsToAnalyze,
            triggerType: "manual"
        });

        setManualAnalysisResult(problemsResult);

        return problemsResult;
    }

    const performManualPromptGeneration = async () => {
        const problemsResult = await performManualCheckForProblems();

        let commentsToAnalyze = getCommentsToAnalyzeAsJsonString({ datastore });

        generateThoughtProvokingPromptFromConversationAsync({
            datastore,
            mergedComments: commentsToAnalyze,
            problemsExplanation: problemsResult.explanation,
            triggeredBy: "admin"
        });
    }

    return <SpacedArray>
            <Banner color={colorLightBlueBackground}>
                {/* "Guidance" refers to Thought-Provoking Prompt but that's a rather clunky name to have in a UI. */}
                <Heading label={"Current Guidance"} />
                <Pad />
                <UtilityText label="This guidance message is visible to everyone." />
                <Pad />
                <View style={{ borderColor: colorGreyBorder, borderWidth: 1 }}>
                    <ThoughtProvokingPromptSelector />
                </View>
                {latestThoughtProvokingPrompt?.explanation && (
                    <View>
                        <Pad />
                        <Banner color={colorWhite}>
                            <UtilityText strong={true} label="This message was generated because..." />
                            <Pad />
                            <Paragraph text={latestThoughtProvokingPrompt.explanation} />
                        </Banner>
                    </View>
                )}
                <Pad />
                <CTAButton type={"secondary"} label="Generate new guidance" onPress={performManualPromptGeneration} />
            </Banner>

            <Banner color={colorLightBlueBackground}>
                <Heading label={"Conversation Analysis"} />
                <Pad />
                {manualAnalysisResult && (
                    <SpacedArray>
                        <HorizBox>
                            <UtilityText label="Result: " strong={true} />
                            {manualAnalysisResult.problemsFound ? (
                                <UtilityText label="Problems detected!" color={colorRed} strong={true} />
                            ) : (
                                <UtilityText label="No problems detected." color={colorGreen} strong={true} />
                            )}
                        </HorizBox>
                        <UtilityText strong={true} label="Explanation" />
                        <UtilityText text={manualAnalysisResult.explanation} />
                    </SpacedArray>
                )}
                <CTAButton type={"secondary"} label="Analyze conversation" onPress={performManualCheckForProblems} />
            </Banner>

            <Banner color={colorPinkBackground}>
                <Heading label="Conversation Stats" />
                <Pad />
                <SpacedArray pad={10}>
                    {lastAnalysisTime ? (
                        <RichText
                            label="- Last conversation analysis: **{time}**"
                            formatParams={{ time: lastAnalysisTimeFormatted }}
                        />
                    ) : (
                        <RichText label={"- Last conversation analysis: **None**"} />
                    )}
                    <RichText
                        label={"- **{count} check(s)** for problems performed"}
                        formatParams={{ count: allAnalysisResults.length }}
                    />
                    <RichText
                        label={"- **{count} guidance message(s)** generated"}
                        formatParams={{ count: thoughtProvokingPrompts.length }}
                    />
                    <RichText
                        label={"- **{count} new comment(s)** since last conversation analysis"}
                        formatParams={{ count: filteredComments.length }}
                    />
                </SpacedArray>
            </Banner>
        </SpacedArray> 
}