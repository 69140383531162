import { callServerApiAsync } from "np-platform-client/util/servercall";
import { logEventAsync } from "np-platform-client/util/eventlog";
import { getRuleDescription } from "../../moderation/AIPreModeratorFeature";


export const BlacklistFeature = {
    name: 'Blacklist',
    key: 'blacklist',
    config: {
        commentModerators: [checkBlacklist],
    }
}

async function checkBlacklist({datastore, comment}) {
    
    if (!comment.text?.trim()) {
        return {allow: true, commentProps: {violates: false, inReview: false}};
    }

    const moderationResult = await callServerApiAsync({datastore, 
        component: 'moderationZdf', funcname: 'checkBlacklist',
        params: {
            text: comment.text, 
            commentKey: comment.key || null, 
            inReview: comment.inReview
        }
    })
    const violations = moderationResult.rulesBroken?.map(getRuleDescription) ?? [];

    return {
        violates: moderationResult.violates ?? false,
        source: 'blacklist', 
        violations,
        metadata: {rulesBroken: JSON.stringify(moderationResult.rulesBroken ?? [])}
    };
}

