import { HoverView } from "np-platform-client/component/basics";
import { View } from "react-native";
import { StyleSheet } from "react-native";
import { UtilityText } from "np-platform-client/component/text";
import { colorBlack, colorGreyBorder, colorGreyHover, colorGreyPopupBackground, colorPinkBackground, colorPurpleBackground, colorRed, colorTeaserBackground, colorTextBlue } from "np-platform-client/component/color";
import { useState } from "react";


export function CountedFilter({label, maxCount, count, color='red', value, selection, onChangeSelection}) {
    const s = RatingSummaryItemStyle;
    const [hover, setHover] = useState(false);
    const selected = selection == value;
    const otherSelected = selection != null && selection != value;
    function onSelect() {
        if (selected) {
            onChangeSelection(null);
        } else {
            onChangeSelection(value);
        }
    }
    return <HoverView onPress={onSelect} setHover={setHover} 
                role='button' ariaLabel={label} testID={label}
                style={[s.outer, 
                    selected ? s.selected : null, 
                    otherSelected ? s.otherSelected : null]}
                hoverStyle={s.hover}
            >
            <View>
                <View style={s.right}>
                    <UtilityText color={selected ? colorTextBlue : colorBlack} strong label={label}/>
                    <UtilityText color={colorRed} text={count}/>
                </View>
                {(!hover || selected) && <BackgroundBar count={count} maxCount={maxCount} 
                    color={selected ? colorPurpleBackground : colorTeaserBackground}/>}
            </View>
    </HoverView>
}


const RatingSummaryItemStyle = StyleSheet.create({
    outer: {
        borderRadius: 4,
        borderColor: colorGreyBorder,
        borderWidth: 1
    },
    selected: {
        borderColor: colorTextBlue
    },
    otherSelected: {
        opacity: 0.5
    },
    hover: {
        backgroundColor: colorGreyPopupBackground
    },
    right: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 12,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 4,
    },
    label: {
        color: '#222',
        fontSize: 14,
        marginLeft: 8
    }
})

export function BackgroundBar({count, maxCount, color=colorTeaserBackground}) {
    const s = BarStyle;
    return <View style={s.frame}>
        <View style={[s.filled, {flex: count}, {backgroundColor: color}]} />
        <View style={[s.empty, {flex: Math.max(maxCount, 4) - count}]} />
    </View>
}
const BarStyle = StyleSheet.create({
    frame: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        flexDirection: 'row',
    },
    filled: {
        backgroundColor: colorTeaserBackground,
        borderRadius: 4,
    },
    empty: {
    }
});