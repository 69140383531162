import { ActionUpvote } from "np-platform-client/feature/UpvoteFeature"
import { ActionReportComment, ReportPopup } from "../feature/moderation/ReportCommentFeature"
import { ActionReply, Comment } from "np-platform-client/component/comment"
import { CLICK, INPUT, POPUP } from "np-platform-client/component/demo"
import { ModerationModal, ModerationReport } from "../feature/moderation/AIPreModeratorFeature"

export const DemoModerationFeature = {
    key: 'demo_moderation',
    name: 'Demo - Moderation Features',
    config: {
        componentSections: [
            {label: 'Moderation', key: 'moderation', pages: [
                {
                    label: 'Report Comment', key: 'report_comment', 
                    designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=5220-2966&t=dlCMQ4bQ0Ij7PDHZ-1', 
                    storySets: reportCommentStorySets
                },
                {
                    label: 'AI Pre-Moderation', key: 'ai_premoderation',
                    designUrl: 'https://www.figma.com/design/F9OCIwB0YSBYvH0TTJ4LbP/AI-Moderation---Testing?node-id=525-4049&node-type=canvas&t=72IdegenOfhw86hI-0',
                    storySets: aiPreModerationStorySets
                }
            ]},
        ]
    }
}

const collections = {
    comment: [
        {key: 1, from: 'b', text: 'People who disagree with me should be tortured.'},
    ]
}

const config = {
    commentActions: [ActionReply, ActionUpvote],
    commentRightActions: [ActionReportComment]
}

const serverCall = {moderation: {
    sendToReview: () => {}
}}


function reportCommentStorySets() {return [
    {
        label: 'Report Comment',
        collections, config, serverCall,
        content: <Comment commentKey={1} />,
        stories: [
            {
                label: 'Report Comment', actions: [
                CLICK('report'), 
                POPUP(CLICK('Uncivil language')), 
                POPUP(CLICK('Calls for harm or celebration of harm')), 
                POPUP(CLICK('Report'))
            ]}
        ]
    },
    {
        label: 'Report Popup',
        collections, config, serverCall,
        content: <ReportPopup commentKey={1} onClose={() => {}} />,
        stories: [
            {label: 'Report Comment', actions: [
                CLICK('Spam or off-topic'), 
                CLICK('Report')
            ]}
        ]
    }
]}

const comment = {
    text: 'People who disagree with me should be tortured.',
    key: 1,
    violates: true,
    violations: ['Avoid talking about vegetables'],
}

function aiPreModerationStorySets() {return [
    {
        label: 'Moderation Report',
        content: <ModerationReport comment={comment} onCancel={() => {}} />,
        serverCall: {moderation: {sendToReview: () => {}}},
        stories: [
            {label: 'Appeal', actions: [
                CLICK('moderation-report'), 
                POPUP(CLICK('Send to moderator')),
                POPUP(INPUT('appeal', 'This was counterspeech. They were mean first.')),
                POPUP(CLICK('Send to moderator')),
                POPUP(CLICK('I understand'))
            ]}
        ]
    }
]}
