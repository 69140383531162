import { View } from "react-native";
import { SubtleButton } from "np-platform-client/component/button";
import { colorTeaserBackground, colorBlackHover } from "np-platform-client/component/color";
import { Pin } from "@carbon/icons-react";
import { useState } from "react";
import { Banner } from "np-platform-client/component/banner";
import { HorizBox, Pad, PadBox, spacings } from "np-platform-client/component/basics"
import { Modal } from "np-platform-client/component/modal";
import { Heading, Paragraph, UtilityText } from "np-platform-client/component/text"
import React from "react";

export const CommunityGuidelinesFeature = {
    name: 'Community Guidelines',
    key: 'community_guidelines',
    config: {
        headerTopLeftWidgets: [CommunityGuidelines]
    }
}

export function CommunityGuidelines() {
    const [showCommunityGuidelines, setShowCommunityGuidelines] = useState(false);
    return <View>
        <SubtleButton type='tiny' icon={Pin} color={colorBlackHover} label='Community Guidelines' onPress={() => setShowCommunityGuidelines(true)}/>
        {showCommunityGuidelines && React.createElement(GuidelinesModal, {onClose: () => setShowCommunityGuidelines(false)})}
    </View>
}

export function GuidelinesModal({onClose}) {
    return <Modal onClose={onClose} >
        <PadBox horiz={spacings.lg} vert={spacings.lg}>
            <Heading level={1} label='Community Guidelines'/>
            <Pad />
            <Paragraph label='We strive for a kind and constructive discussion space where everyone feels welcome to contribute their perspectives and lived experiences to the conversation. This discussion space values responses that are on-topic and relevant to the subject matter, including meaningful debate.' />
            <Pad size={21} />
            <Paragraph label='To foster a welcoming and respectful environment, this discussion space uses AI-aided moderation to enforce the following community guidelines:' />
            <Pad size={32} />
            <Banner color={colorTeaserBackground}>
                <View style={{flexDirection: 'row'}}>
                    <UtilityText text='📌' type='large'/>
                    <Pad size={4} />
                    <Heading level={2} label='Community Guidelines'/>  
                </View>
                <Pad size={16}/>
                <BulletText label='No uncivil, hateful, or illegal content' />
                <Pad size={12}/>
                <BulletText label='No comments that call for or celebrate harm' />
                <Pad size={12}/>
                <BulletText label='No insults, mockery, threats, or personal attacks' />
                <Pad size={12}/>
                <BulletText label='Refrain from hostile comments that attach negative labels to, or assume bad intent of specific groups of people' />
                <Pad size={12}/>
                <BulletText label='Back up contentious claims with sources' />
                <Pad size={12}/>
                <BulletText label='No spam or commercial content' />            
            </Banner>
        </PadBox>
    </Modal>    
}

function BulletText({label, text}) {
    return <HorizBox>
        <UtilityText text='•' />
        <Pad size={10}/>
        <UtilityText label={label} text={text} />
    </HorizBox>
}