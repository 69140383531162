import { Banner, ClickableBanner } from "np-platform-client/component/banner";
import { Pad, PadBox } from "np-platform-client/component/basics";
import { CTAButton } from "np-platform-client/component/button";
import { colorBlueBackground, colorPink } from "np-platform-client/component/color";
import { RichText } from "np-platform-client/component/richtext";
import { Heading, TextField, UtilityText } from "np-platform-client/component/text";
import { useDatastore } from "np-platform-client/util/datastore";
import { useState } from "react";
import { Modal } from "np-platform-client/component/modal";
import { goBack } from "np-platform-client/util/navigate";
import { logEventAsync, useLogEvent } from "np-platform-client/util/eventlog";

export const PreModeratorFeature = {
    name: 'Pre Moderation',
    key: 'premoderation',
    config: {
        commentPostBlockers: [isPostButtonDisabledAsync],
        commentEditBottomWidgets: [ModerationReport],
        commentPostCheckers: [checkPostAsync]
    },
    defaultConfig: {
        commentModerators: [],
        useOnlyGptModeration: false
    }
}

export const AIModerationFeature = {
    name: 'Jigsaw + GPT Moderation',
    key: 'aimoderation',
    config: {
        commentModerators: [moderateWithAI]
    }
}

export const ModerateRepliesStrictlyFeature = {
    name: 'Strict Reply Moderation',
    key: 'strictreplymoderation',
    config: {
        commentModerators: [moderateRepliesStrictly]
    }
}

export const UseOnlyGptModerationFeature = {
    name: 'Only GPT Moderation (filters spam)',
    key: 'onlygptmoderation',
    config: {
        commentModerators: [moderateWithAI],
        useOnlyGptModeration: true
    }
}

export const UseNoAIModerationFeature = {
    name: 'No AI Moderation',
    key: 'noaimoderation',
    config: {
        commentModerators: []
    }
}


const default_rule = '**Make sure your response follows our community guidelines**'

export function getRuleDescription(rule) {
    switch (rule) {
        case 0:
        case 1:
        case 6:
            return '**Avoid uncivil or disrespectful language**. You can express any opinion, however you must do so in a respectful way.';
        case 2:
            return '**Avoid calls for harm or celebration of harm**. You can advocate for actions that would have negative consequences for some people, but harm should not be the primary goal.';
        case 3:
            return '**Do not imply that someone has bad intent**. Assume they have good intentions and critique their ideas and their actions instead.';
        case 4:
            return '**Avoid attacking the character of others**. Critique their ideas and their actions instead.';
        case 5:
            return '**Avoid snark, sarcasm, and mockery**';
        case 7:
            return '**Controversial claims must provide a source URL**';
        case 8:
            return '**Do not promote irrelevant products or services**'
        default:
            return default_rule;
    }
}

async function moderateWithAI({datastore, comment}) {
    const { useOnlyGptModeration } = datastore.getConfig();
    const params = {text: comment.text, commentKey: comment.key || null, 
        inReview: comment.inReview, useOnlyGpt: useOnlyGptModeration};
    const moderationResult = await datastore.callServerAsync('moderation', 'moderateComment', params);
    const violations = moderationResult.rulesBroken?.map(getRuleDescription) ?? [];
    return {
        violates: moderationResult.violates, 
        source: 'ai-premoderation',
        violations,
        metadata: {rulesBroken: JSON.stringify(moderationResult.rulesBroken ?? [])}
    };
}

const strictReplyDescription = '**Avoid picking fights** If you disagree with someone, speak as if you are open to them being right, and invite them to explain their position better to you.'; 

async function moderateRepliesStrictly({datastore, comment}) {
    if (!comment.replyTo) {
        return {violates: false}
    }
    const parentComment = await datastore.getObject('comment', comment.replyTo);
    const moderationResult = await datastore.callServerAsync('moderation', 'moderateReplyStrictly', {
        parentText: parentComment.text, text: comment.text, 
        commentKey: comment.key || null, inReview: comment.inReview
    });
    if (!moderationResult.allow) {
        return {violates: true, source: 'ai-reply-moderaton',
            violations: [strictReplyDescription],
        }
    } else {
        return {violates: false}
    }
}

async function checkPostAsync({datastore, comment}) {
    const {commentModerators} = datastore.getConfig();

    if (!comment.text?.trim()) {
        return {allow: true, commentProps: {violates: false, inReview: false}};
    }

    var violation = null;
    var violations = [];
    for (const moderator of commentModerators) {
        const moderationResult = await moderator({datastore, comment});
        if (moderationResult.violates) {
            violations = [...violations, ...moderationResult.violations];
            violation = moderationResult;
            logEventAsync(datastore, 'moderation-block', {text: comment.text});
            break;
        }
    }
    
    const violates = violations.length > 0;

    return {allow: !violates, commentProps: {
        violates, inReview: false, violations,
        violatingText: violates ? comment.text : null,
    }};
}

function isPostButtonDisabledAsync({datastore, comment}) {
    return comment.violates && comment.violatingText == comment.text;
}

export function ModerationReport({comment, onCancel}) {
    const [modalShown, setModalShown] = useState(false);
    if (!comment.violates) {
        return null;
    } else {
        // TODO: Show all violations, not just the first
        return <PadBox bottom={20}> 
            <ClickableBanner testID='moderation-report' color={colorPink} onPress={() => setModalShown(true)}>
                <RichText label={comment.brokenRuleDescription ?? comment.violations?.[0] ?? default_rule} />
            </ClickableBanner>
            {modalShown && <ModerationModal comment={comment} onCancel={onCancel} onClose={() => setModalShown(false)} />}
        </PadBox>
    }
}

// TODO: Bring back link to transparency dashboard, once we have cross-partner alignment
// and it works better

export function ModerationModal({comment, onClose, onCancel}) {
    const [inProgress, setInProgress] = useState(false);
    const [appealing, setAppealing] = useState(false);
    const [sent, setSent] = useState(false);
    const [appealText, setAppealText] = useState('');
    const datastore = useDatastore();
    const siloKey = datastore.getSiloKey();
    useLogEvent('moderation-modal', {text: comment.text});
    async function onReport() {
        setInProgress(true);
        await datastore.callServerAsync('moderation', 'sendToReview', {
            type: 'comment', setInReview: true, source: 'premoderation',
            text: comment.text, key: comment.key, from: comment.from,
            violations: JSON.stringify(comment.violations), appealText
        });
        setInProgress(false);
        setSent(true);
        logEventAsync(datastore, 'moderation-appeal', {text: appealText});
    }

    function onDone() {
        onClose();
        if (onCancel) {
            onCancel();
        } else {
            goBack();
        }
    }

    const dashboardUrl = 'https://np-psi-dev.web.app/' + siloKey + '/moderationdash/dash';

    if (sent) {
        return <Modal onClose={onClose} buttonRow={
            <CTAButton wide type='secondary' label='I understand' onPress={onDone}/>
        }>
            <PadBox horiz={20} vert={20}>
                <Heading level={1} label='Your response has been sent to a moderator for review'/>
                <Pad />
                <RichText label='You will be notified when the moderator makes a decision. In the meantime, you can look at the [transparency dashboard]({dashboardUrl}) to see where your post is in our moderation queue.' formatParams={{dashboardUrl}}/> 
            </PadBox>  
        </Modal>
    } else if (appealing) {
        return <Modal onClose={onClose} buttonRow={
            <CTAButton disabled={inProgress || !appealText} wide type='primary' label={inProgress ? 'Sending...' : 'Send to moderator'} onPress={onReport}/>
        }>
            <PadBox horiz={20} vert={20}>
                <Heading level={1} label='Send to moderator'/>
                <Pad />
                <UtilityText caps strong label='Message to moderator*' />
                <Pad size={12} />
                <TextField testID='appeal' big value={appealText} onChange={setAppealText} placeholder='Please explain your response (required)'/>
            </PadBox>  
        </Modal>
    } else {
        return <Modal onClose={onClose} buttonRow={<CTAButton wide type='secondary' label='Send to moderator' onPress={() => setAppealing(true)}/>}>
            <PadBox horiz={20} vert={20}>
                <Heading level={1} label='✋ Take another look'/>
                <Pad size={16} />
                <RichText label='Your post may violate our [Community Guidelines](https://example.com).'/>
                <Pad size={16} />
                <RichText label='You are free to express any opinion, however you must do so in a respectful way that avoids personal attacks, assumes good intent, and backs up contentious claims with sources.'/>
                <Pad size={16} />
                <Banner color={colorBlueBackground}>
                    <RichText label='**Sometimes our AI triggers incorrectly**'/>
                    <RichText label='If that happened then first try adjusting your wording to be more obviously respectful. If you are still unable to get the AI to accept a respectfully expressed opinion then please send it to our human moderator.'/>
                </Banner>
                <Pad size={16} />
                {/* <RichText label='To provide transparency, we offer an anonymized [public database]({dashboardUrl}) of all moderation decisions.' formatParams={{dashboardUrl}}/> */}
            </PadBox>
        </Modal>
    }
}
