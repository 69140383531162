import { CTAButton } from "np-platform-client/component/button";
import { HumanModerationModal, ModerationNote, sendPostToModeration, showModalAfterPosting } from "../feature/moderation/HumanReviewsEverythingFeature"
import { StructureDemo } from "np-platform-client/util/instance"
import { useState } from "react";
import React from "react";
import { BasicComments, Composer, EditComment } from "np-platform-client/component/comment";
import { CLICK, POPUP } from "np-platform-client/component/demo";
import { View } from "react-native-web";

export const DemoFallbacksFeature = {
    key: 'demo_fallbacks',
    name: 'Demo - Fallbacks',
    config: {
        componentSections: [
            {label: 'Moderation', key: 'moderation', pages: [
                {
                    label: 'Human Review Everything',
                    key: 'human_moderate_all',
                    designUrl: 'https://www.figma.com/design/kf97PnjRjvYiMosLYnItoF/Graceful-Fallbacks---Testing?node-id=63-200&node-type=canvas&m=dev',
                    storySets: fallbacksStorySets
                }
            ]}
        ],
        featureSections: [
            {label: 'Question', key: 'conversationfeatures', pages: [
                {label: 'Human Reviews Everything', key: 'fallbacks', screen: HumanPreModerationFullDemo},
            ]},
        ]
    }
}

function ModalButton({modal}) {
    const [open, setOpen] = useState(false);
    return <>
        <CTAButton onPress={() => setOpen(true)} label='Open Modal'/>
        {open && React.createElement(modal, {onClose: () => setOpen(false)})}
    </>
}



function fallbacksStorySets() {return [
    {
        label: 'Moderation Note',
        content: <ModerationNote />   
    },
    {
        label: 'Moderation Modal',
        content: <ModalButton modal={HumanModerationModal} />,
        stories: [
            {label: 'Open Modal', actions:[
                CLICK('Open Modal'),
            ]},
            {label: 'Open and Close Modal', actions:[
                CLICK('Open Modal'),
                POPUP(CLICK('I understand'))
            ]},
        ]
    },
    {
        label: 'Moderation Composer',
        config: {
            commentPostCheckers: [showModalAfterPosting], 
            commentPostTriggers: [sendPostToModeration]
        },
        serverCall: {moderation: {
            sendToReview: () => {}
        }},    
        content: <Composer goBackAfterPost />,
        stories: [

        ]
    }
]}

const globals = {
    name: 'Is Godzilla Good?',
    questionAuthor: 'a',
    questionTime: Date.now(),
}


function HumanPreModerationFullDemo() {
    return <StructureDemo globals={globals} structureKey='question' features={{human_premoderation: true}} />
}


